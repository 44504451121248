<template>
  <section>
    <div class="searchwrap">
       <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.date') }}</span></div>
           <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" />
         </div>
       </div>
       <div class="searchBar">
          <div class="box-ui-select searchbar-content">
            <div class="title">
              <span>{{ $t('searchArea.siteID') }}</span>
            </div>
            <input type="text" class="mr-5" v-model="reqData.botCompId" :placeholder="'ID'" />
          </div>
          <div class="box-ui-select searchbar-content" v-if="this.type === 'is'" >
            <div class="title">
              <span>{{ $t('searchArea.id') }}</span>
            </div>
            <div>
              <input type="text" class="mr-5" v-model="reqData.memId" :placeholder="'ID'" />
              <button class="btn-search" type="button" @click="pageSeach">
                 <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
          <div class="fx">
            <button class="btn-innerTable btn-status-change refuse btn-link" type="button" @click="openGameList('casino')">{{ $t('table.head.casino') }} {{ casinoOpenActive ? $t('button.close') : $t('button.open') }}</button>
            <button class="btn-innerTable btn-status-change refuse btn-link" type="button" @click="openGameList('slot')">{{ $t('table.head.slot') }} {{ slotOpenActive ? $t('button.close') : $t('button.open') }}</button>
          </div>
       </div>
       <div class="searchBar gameList">
         <div class="box-ui-check" v-if="casinoOpenActive">
           <div class="check-list-title">
             <i class="fas fa-dice"></i>
             <span>{{ $t('table.head.casino') }}  :</span>
           </div>
           <div class="check-list-content">
             <input type="checkbox" id="game_all" value="all" @change="gameAllChk" v-model="gameAllYn" />
             <label for="game_all">
               <i class="fas fa-check"></i>
               <span>{{ $t('searchArea.total') }}</span>
             </label>
             <template v-for="item in casinoList" :key="item.gameType">
               <input type="checkbox" :id="`game_${item.gameType}`" v-model="item.yn" />
               <label :for="`game_${item.gameType}`">
                 <i class="fas fa-check"></i>
                 <span>{{ item.gameTxtKey }}</span>
               </label>
             </template>
           </div>
         </div>
         <div class="box-ui-check" v-if="slotOpenActive">
           <div class="check-list-title">
             <i class="fas fa-leaf"></i>
             <span>{{ $t('table.head.slot') }}  :</span>
           </div>
           <div class="check-list-content">
             <input type="checkbox" id="game_all" value="all" @change="gameAllChk" v-model="gameAllYn" />
             <label for="game_all">
               <i class="fas fa-check"></i>
               <span>{{ $t('searchArea.total') }}</span>
             </label>
             <template v-for="item in slotList" :key="item.gameType">
               <input type="checkbox" :id="`game_${item.gameType}`" v-model="item.yn" />
               <label :for="`game_${item.gameType}`">
                 <i class="fas fa-check"></i>
                 <span>{{ item.gameTxtKey }}</span>
               </label>
             </template>
           </div>
         </div>
       </div>
       <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>
          </div>
        </div>
      </article>
      <div class="tab-wrap">
        <button type="button" @click="onChangeType('is')" class="tab-item" :class="{'active': type === 'is'}">하부 보험 베팅내역</button>
        <button type="button" @click="onChangeType('real')" class="tab-item" :class="{'active': type === 'real'}">하부 실시간 베팅내역</button>
      </div>
      <div class="table-wrapper" v-if="type === 'is'">
        <table class="mainTable">
          <thead>
            <tr>
              <th rowspan="2">{{ $t('table.head.idx') }}</th>
              <th rowspan="2">{{ $t('searchArea.userID') }}<br />({{ $t('table.head.comId') }})</th>
              <th rowspan="2">{{ $t('table.head.betType') }}</th>
              <th rowspan="2">{{ $t('table.head.gameUniqueId') }}</th>
              <th rowspan="2">{{ $t('table.head.betDate') }}</th>
              <th rowspan="2">{{ $t('table.head.ubetAmt') }}</th>
              <th rowspan="2">{{ $t('table.head.betAmt') }}</th>
              <th rowspan="2">{{ $t('table.head.betwlt') }}</th>
              <th colspan="5" class="h25">{{ $t('table.head.insureBet') }}</th>
              <th colspan="4" class="h25">{{ $t('table.head.BIAmt') }}</th>
            </tr>
            <tr>
              <!-- 보험베팅 -->
              <th class="h25">{{ $t('table.head.insureApplyRate') }}</th>
              <th class="h25">{{ $t('table.head.insureBetAmt') }}</th>
              <!--th class="h25">{{ $t('table.head.insureBetStdAmt') }}</th-->
              <th class="h25">{{ $t('table.head.preInsuredAmt') }}</th>
              <th class="h25">{{ $t('table.head.insurewl') }}</th>
              <th class="h25">{{ $t('table.head.aftInsureAmt') }}</th>
              <!-- 보험포인트 -->
              <th class="h25">{{ $t('table.head.rate') }}</th>
              <th class="h25">{{ $t('table.head.preApplyPT') }}</th>
              <th class="h25">{{ $t('table.head.payPT') }}</th>
              <th class="h25">{{ $t('table.head.aftInsuraftApplyeAmt') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="tableData.length !== 0">
              <tr v-for="(item, idx) in tableData" :key="idx">
                <td class="roboto">{{ idx + 1 }}</td>
                <td>
                  <template v-if="siteInfo.siteId === item.botSiteId">
                    <button type="button" class="fc-id btn-link" @click="detailOpen('user',item.botMemId)" >{{item.botMemId}}</button>
                  </template>
                  <template v-else>
                    <button type="button" class="fc-id btn-link" @click="detailOpen('company',item.botSiteId)" >{{item.botSiteId}}</button>
                  </template>
                </td>
                <td>{{ item.vendorName }}</td>
                <td>
                   <div class="btnWrap">
                      <button type="button" class="fc-btid btn-link copytxt">{{ item.betId }}</button>
                      <button class="copybtn" @click="copyToClipboard(item.betId)"></button>
                   </div>
                </td>
                <td class="roboto">{{ replaceDateT(item.betRegDate || item.regDate) }}</td>

                <td class="roboto">{{ numberWithCommas(item.betAmt) }}</td>
                <td class="roboto">{{ numberWithCommas(item.biBetAmt) }}</td>
                <td v-if="Number(item.betWlAmt) > 0" class="fc-red roboto">+{{ numberWithCommas(item.betWlAmt) }}</td>
                <td v-if="Number(item.betWlAmt) < 0" class="fc-blue roboto">{{ numberWithCommas(item.betWlAmt) }}</td>
                <td v-if="Number(item.betWlAmt) == 0" class="roboto">{{ numberWithCommas(item.betWlAmt) }}</td>

                <td class="roboto">{{ item.baseInsureBetAmt == '0' ? item.insureRate + '%' : numberWithCommas(item.baseInsureBetAmt) }}</td>
                <td class="roboto">{{ numberWithCommas(item.insuredBetAmt) }}</td>
                <td class="roboto">{{ numberWithCommas(item.bfInsurance) }}</td>
                <td class="roboto">{{ numberWithCommas(item.insuranceAmt) }}</td>
                <td class="roboto">{{ numberWithCommas(Number(item.bfInsurance) + Number(item.insuranceAmt)) }}</td>

                <td class="roboto">{{ Number(item.biSubPointRate).toFixed(2) }} %</td>
                <td class="roboto">{{ numberWithCommas(item.bfBiSubPoint) }}</td>
                <td class="roboto" :class="Number(item.myBiSubPointAmt) < 0 ? 'fc-red' : 'fc-blue'">{{ numberWithCommas(item.myBiSubPointAmt) }}</td>
                <td class="roboto">{{ numberWithCommas(Number(item.bfBiSubPoint) + Number(item.myBiSubPointAmt)) }}</td>
              </tr>
            </template>
            <template v-else>
              <td colspan="17">{{ $t('txt.noData') }}</td>
            </template>
          </tbody>
        </table>
      </div>
      <div class="table-wrapper" v-if="type === 'real'">
        <table class="mainTable">
          <thead>
            <tr>
              <th>{{ $t('table.head.idx') }}</th>
              <th>{{ $t('common.id') }}</th>
              <th>{{ $t('table.head.betType') }}</th>
              <th>{{ $t('table.head.gameType') }}</th>
              <th>{{ $t('table.head.gameName') }}</th>
              <th>{{ $t('table.head.gameIdx') }}</th>
              <th>{{ $t('table.head.gameUniqueId') }}</th>
              <th>{{ $t('table.head.betDate') }}</th>
              <th>{{ $t('table.head.betAmt') }}</th>
              <th>{{ $t('table.head.winAmt') }}</th>
              <th>{{ $t('table.head.wl') }}</th>
              <th>{{ $t('table.head.statusPT') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="tableData.length !== 0">
              <tr v-for="(item, idx) in tableData" :key="idx">
                <td class="roboto">{{ idx + 1 }}</td>
                <td>
                  <button type="button" class="fc-id btn-link" @click="detailOpen('company',item.siteId)" >{{item.siteId}}</button>
                </td>
                <td> {{ item.vendorName }} </td>
                <td> {{ item.gameCategory }} </td>
                <td> {{ item.gameName }} </td>
                <td class="roboto"> {{ item.betIdx }} </td>
                <td>
                  <button type="button" class="fc-id btn-link" @click="betDetailOpen(item)">
                    {{ item.betId }}
                  </button>
                </td>
                <td class="roboto"> {{ item.regDate ? item.regDate.replace('T', ' ') : '' }} </td>
                <td class="roboto"> {{ numberWithCommas(item.betAmt) }} </td>
                <td class="roboto"> {{ numberWithCommas(item.betWinAmt) }} </td>
                <td class="roboto" :class="Number(item.resultAmt) < 0 ? 'fc-red' : 'fc-blue'"> {{ numberWithCommas(item.resultAmt) }} </td>
                <td> {{ item.result }} </td>
              </tr>
            </template>
            <template v-else>
              <td colspan="12">{{ $t('txt.noData') }}</td>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>

  <bet-detail v-if="isDetailOpen" :meta="detailMeta" :type="'insure'" @close="betDetailClose"></bet-detail>
</template>

<script>
import lodash from 'lodash'
import DateSelector from '@/components/common/DateSelector'
import TableHead from '@/components/main/table/Head.vue'
import UiCheck from '@/components/ui/UiCheckSet'
import subTitle from '@/components/main/PageSubTitle'
import { getCode } from '@/api/member.js'
import { insureBetBotList, insureBetRealBotList } from '@/api/insured.js'
import { GAME_INFO_LIST, GAME_CODE_NAME } from '@/libs/constants'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import BetDetail from '@/views/member/bet/pages/betDetail.vue'
import Memo from '@/components/common/memo'

export default {
  name: 'insuredAccumulation',
  components: {
    BetDetail,
    DateSelector,
    TableHead,
    UiCheck,
    subTitle,
    Pagination,
    Memo
  },
  data: function () {
    return {
      type: 'is',
      siteInfo: {},
      tableData: [],
      headInfo: {
        fstColumn: false,
        dataList: ['index', 'siteId', 'memId', 'betType', 'betDate', 'betAmt', 'winAmt', 'wl', 'BIRate', 'result', 'insurePointRate', 'insurePoint']
      },
      reqData: {
        botCompId: '',
        page: 1,
        count_per_list: '30',
        memId: '',
        startDate: '',
        endDate: '',
        vendorCode: null
      },
      gameAllYn: true,
      casinoList: [],
      slotList: [],
      betList: [],
      codeList: [],
      convertData: {},
      gameType: {},
      tabList: {},
      currentPage: '',
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      pageInfo: {
        tatal_list_count: 0,
        page: 0,
        tatal_page_count: 0
      },
      summary: {
        comma: '',
        origin: 0
      },
      startDefault: '',
      endDefault: '',
      slotOpenActive: false,
      casinoOpenActive: false,
      detailMeta: null,
      isDetailOpen: false
    }
  },
  watch: {
    gameList: {
      deep: true,
      handler (gameList) {
        for (const item of gameList) {
          if (!item.yn) {
            this.gameAllYn = false
            break
          }
        }
      }
    }
  },
  methods: {
    copyToClipboard (text) {
      navigator.clipboard.writeText(text)
        .then(() => {
          alert('클립보드에 복사했습니다')
        })
    },
    async betDetailOpen (item) {
      this.detailMeta = item
      this.isDetailOpen = true
    },
    async betDetailClose () {
      this.detailMeta = null
      this.isDetailOpen = false
    },
    onChangeType (type) {
      this.type = type

      this.tableData = []

      if (type === 'real') {
        this.setRealTableData(1)
      } else {
        this.setTableData(1)
      }
    },
    openGameList (type) {
      this[`${type}OpenActive`] = !this[`${type}OpenActive`]
    },
    gameAllChk (e) {
      const target = e.target
      const val = target.checked
      console.log(val)
      for (const item of this.gameList) {
        item.yn = val
      }
    },
    pageSeach () {
      if (this.type === 'real') {
        this.setRealTableData(1)
      } else {
        this.setTableData(1)
      }
    },
    setStartDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setDate(1)
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
        _date.setHours(0, 0, 0)
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
        _date.setHours(23, 59, 59)
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    async setGameList () {
      const gameList = await this.getGameCode('kplay')
      // console.log(gameList);
      if (gameList.length !== 0) {
        for (const item of gameList) {
          if (item.useYn === 'Y') {
            if (item.groupCode === 'casino') {
              const rateObj = {
                gameTxtKey: item.codeName,
                gameType: item.code,
                yn: true
              }
              this.casinoList.push(rateObj)
            }
            if (item.groupCode === 'slot') {
              const rateObj = {
                gameTxtKey: item.codeName,
                gameType: item.code,
                yn: true
              }
              this.slotList.push(rateObj)
            }
          }
        }
      }
    },
    async getBetList (code, pageNum) {
      if (pageNum) {
        this.reqData.page = pageNum
      }
      this.reqData.gameType = code
      const data = this.reqData
      return await insureBetBotList(data)
    },
    async getGameCode (reqData) {
      const data = reqData
      return await getCode(data)
    },
    async setTableData (page) {
      this.emitter.emit('Loading', true)
      const req = lodash.cloneDeep(this.reqData)
      if (!page) {
        page = 1
      }
      req.page = page

      console.log(req)
      const res = await insureBetBotList(req)
      console.log(res)
      const list = res.data.list
      if (list) {
        this.tableData = list
      }
      const pageInfo = res.data.pageInfo
      if (pageInfo) {
        this.pageInfo = pageInfo
        this.pageInfo.tatal_page_count = Math.ceil(Number(this.pageInfo.tatal_list_count) / Number(this.reqData.count_per_list))
      }
      this.emitter.emit('Loading', false)
    },
    async setRealTableData (page) {
      this.emitter.emit('Loading', true)
      const req = lodash.cloneDeep(this.reqData)
      if (!page) {
        page = 1
      }
      req.page = page
      req.gameCategory = 'casino'
      req.memId = ''

      console.log(req)
      const res = await insureBetRealBotList(req)
      console.log(res)
      const list = res.data.list
      if (list) {
        list.forEach(item => {
          const type = item.type
          const betAmt = Number(item.betAmt)
          const betWinAmt = Number(item.betWinAmt)

          item.resultAmt = betAmt - betWinAmt
          if (type === 'win') {
            item.result = '승리'
          } else if (type === 'lose') {
            item.result = '패배'
          } else if (type === 'draw') {
            item.result = '무'
          } else if (type === 'wait') {
            item.result = '대기'
          } else {
            item.result = '취소'
          }
        })

        this.tableData = list
      }
      const pageInfo = res.data.pageInfo
      if (pageInfo) {
        this.pageInfo = pageInfo
        this.pageInfo.tatal_page_count = Math.ceil(Number(this.pageInfo.tatal_list_count) / Number(this.reqData.count_per_list))
      }

      this.emitter.emit('Loading', false)
    }
  },
  async created () {
    this.siteInfo = this.getSiteInfo()
    this.setStartDate()
    this.setEndDate()
    await this.setGameList()
    await this.setTableData()
  }
}
</script>

<style scoped>
.h25 {height: 25px;}
.mainTable th {border: solid 1px #cacaca;}
.btnWrap {display: flex;align-items: center;justify-content: center;}
.copytxt {white-space: nowrap;text-overflow: ellipsis;overflow: hidden;max-width: 150px;}
.copybtn {
  margin-left: 10px;
  width: 20px;
  height: 20px;
  background: url(~@/assets/img/icon_copy.svg) no-repeat;
  background-size: cover;
  cursor: pointer;
}
.main-sub-data-item {
  flex: 1 1 0;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0px #d1d1d1;
  display: flex;
}
.main-sub-data-item > div {
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
}
.sub-data-item-title {
  background: #ab2926;
  color: #fff;
  font-weight: 700;
  width: 35%;
}
.sub-data-item-content {
  font-weight: 700;
  text-align: right;
  width: 65%;
}
.main-sub-data-item:last-child {
  margin-right: 0;
}
.page-contents-wrap {
  margin-bottom: 10px;
  display: flex;
}
.page-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.btn-total-data {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background: #2f4858;
  width: fit-content;
  border-radius: 3px;
  border: 2px solid #2f4858;
}
.btn-total-data:hover {
  color: #2f4858;
  background: #f1f1e6;
}
.btn-total-data > i {
  margin-right: 5px;
}
.btn-total-data > span {
  font-weight: 700;
  letter-spacing: 2px;
}
.searchBar.gameList {
  flex-direction: column;
  align-items: flex-start;
  min-height: unset;
}
.searchBar.gameList .check-list-content {
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: 4;
  gap: 10px;
  padding: 10px 0;
}
.box-ui-check input[type="checkbox"] + label {flex: 0 0 13%;}
.searchBar.gameList > .box-ui-check {
  border-bottom: 1px solid #f0f0f0;
  flex-wrap: nowrap;
  width: 100%;
}
.searchBar.gameList > .box-ui-check:last-child {
  border-bottom: 0;
}
.check-list-title {
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 8px;
  flex: 0 0 auto;
  min-width: 80px;
}
.check-list-title > i {
  margin-right: 5px;
}
.tab-item:hover { background: #eb7a3f; color: #fff;}
.tab-item.active {background: #eb7a3f;}
</style>
